import 'slick-carousel';
import 'magnific-popup';
import Isotope from 'isotope-layout';
import watchHeight from "./watchHeight";
import scrollListener from "./scrollListener";

const $win = $(window);
const $doc = $(document);

// Your code goes here...
// jQuery.ready is no longer needed

scrollListener({ switchAt: 140 });

watchHeight({
  selector: "#alert-bar-desktop",
  prop: "--alert-bar-desktop-height",
});

watchHeight({
  selector: "#alert-bar-mobile",
  prop: "--alert-bar-mobile-height",
});

homeSlider();

homeIsotope();

eventsIsotope();

mobileSliderEvents();

navTrigger();

// NOTE: WTF is this even supposed to do? It just triggers JS errors on desktop
// navDropdownClick();

accordion();

historyPopups();

videoPopups();

expandEventContent();

$win.on('load', function() {
	homeIsotope();
});

function homeSlider() {
	$('.slider .slider__slides').slick({
		dots: false,
		arrows: false,
		infinite: true,
		autoplay: true,
		autoplaySpeed: 3000,
		adaptiveHeight: true,
		fade: true
	});
}

function homeIsotope() {
	const grid = document.querySelector('.section-shows .section__grid');
	if ( grid != null ) {
		const istp = new Isotope(grid, {
			itemSelector: '.section-shows .section__column',
			percentPosition: true,
			masonry: {
				columnWidth: '.section-shows .section__column'
			}
		});
		istp.layout();
	}
}

function eventsIsotope() {
	const events = document.querySelector('.events');
	if ( events != null ) {
		const istp = new Isotope(events, {
			itemSelector: '.events .event',
			percentPosition: true,
			masonry: {
				columnWidth: '.events .event'
			}
		});
		istp.layout();

		$('.events-filter').on( 'click', 'a', function(e) {
			let $parent = $(this).parent();

			$parent.addClass('active').siblings().removeClass('active');

			istp.arrange({ filter: $parent.data('filter') });

			e.preventDefault();
		});

		$('.event-reset').on('click', function(e) {
			$('.events-filter li').addClass('active');
			istp.arrange({ filter: '*' });
			e.preventDefault();
		});
	}
}

function navTrigger() {
	$('.nav-trigger').on('click', function(e) {
		$('body').toggleClass('header-visible');
		e.preventDefault();
	})
}

function mobileSliderEvents() {
	if ( $('.section-events .section__events--slider').length) {
		const sliderSettings = {
			dots: false,
			arrows: true,
			adaptiveHeight: true,
			mobileFirst: true,
			responsive: [
				{
					breakpoint: 1023,
					settings: 'unslick'
				}
			]
		}
		const sliderBenefits =  $('.section-events .section__events--slider').slick(sliderSettings);

		$win.on('resize', function() {
			if ( $win.width() < 1024 && !sliderBenefits.hasClass('slick-initialized')) {
				sliderBenefits.slick(sliderSettings)
			}
		});
	}
}

function navDropdownClick() {
	$('.nav .menu-item-has-children > a').one('click', !isMobile());
}

function isMobile() {
	return /Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

function accordion() {
	$('.accordion__head').on('click', function(e) {
		$(this).next().slideToggle()
			.parent().toggleClass('accordion__section--expanded')
			.siblings().removeClass('accordion__section--expanded')
				.find('.accordion__body').slideUp();
		e.preventDefault();
	});
}

function historyPopups() {
	$('.timeline .timeline__event, .header__link a[href^=#]').magnificPopup({
		type: 'inline'
	});
}

function videoPopups() {
	$('.section__videos a').magnificPopup({
		type: 'iframe'
	});
}

function expandEventContent() {
	$('.section-event .link-more').on('click', function(e) {
		$(this).parents('.section__artist').toggleClass('section__artist--expanded');
		e.preventDefault();
	});
}
