const throttle = (callback, wait, immediate = false) => {
  let timeout = null;
  let initialCall = true;

  return () => {
    const callNow = immediate && initialCall;
    const next = () => {
      callback.apply(this, arguments);
      timeout = null;
    };

    if (callNow) {
      initialCall = false;
      next();
    }

    if (!timeout) {
      timeout = setTimeout(next, wait);
    }
  };
};

const setHeightProp = ({ prop, value }) => {
  document.documentElement.style.setProperty(prop, `${value}px`);
};

const watchHeight = ({ selector = "[data-watch-height]", prop = null }) => {
  const watchedElement = document.querySelector(selector);

  if (!watchedElement) return;

  if (!prop)
    prop = `--${
      watchedElement.id ||
      watchedElement.classList.value.replace(" ", "-") ||
      "watched-element"
    }-height`;

  setHeightProp({
    prop,
    value: watchedElement.offsetHeight,
  });

  window.addEventListener(
    "resize",
    throttle(() => {
      setHeightProp({
        prop,
        value: watchedElement.offsetHeight,
      });
    }, 300)
  );
};

export default watchHeight;
