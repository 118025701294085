export const checkScroll = ({ switchAt }) => {
  let atTop = true;

  if (window.scrollY > switchAt) {
    document.documentElement.classList.add("not-at-top");
    document.documentElement.classList.remove("at-top");
    atTop = false;
  } else {
    document.documentElement.classList.remove("not-at-top");
    document.documentElement.classList.add("at-top");
  }

  return atTop;
};

const scrollListener = ({ switchAt = 0 }) => {
  console.log("Initialize scrollListener");
  checkScroll({ switchAt });

  document.addEventListener("scroll", function (e) {
    checkScroll({ switchAt });
  });
};

export default scrollListener;
